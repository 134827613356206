var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pop_container"},[_c('div',{staticClass:"title_area"},[_vm._v(_vm._s(_vm.undeliveredAction)),_c('a',{staticClass:"close-btn",on:{"click":_vm.closeModal}},[_vm._v("X")])]),_c('div',{staticClass:"body_area"},[_c('div',{staticClass:"form_section_generate_slip"},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('table',[_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('ValidationProvider',{attrs:{"name":"comment","rules":"required|max:100","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                  Instructions\n                  "),_c('span',[_vm._v("*")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.statuscourier.note),expression:"statuscourier.note"}],class:errors[0] ? 'error-border':'',attrs:{"placeholder":"Instructions here Character Limit 100","maxlength":_vm.max1},domProps:{"value":(_vm.statuscourier.note)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.statuscourier, "note", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('ValidationProvider',{attrs:{"name":"comment","rules":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                  Email\n                  "),_c('span',[_vm._v("*")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.statuscourier.note),expression:"statuscourier.note"}],class:errors[0] ? 'error-border':'',attrs:{"placeholder":"","maxlength":_vm.max1},domProps:{"value":(_vm.statuscourier.note)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.statuscourier, "note", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"logo","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Upload Image\n                  ")]),_c('label',{staticClass:"user_profile_upload "},[_c('span',[_vm._v("Choose a File")]),_c('input',{ref:"logo",class:errors[0] ? 'warning-border' : '',attrs:{"type":"file","accept":"image/*"}})])])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"terms","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Upload Document\n                    ")]),_c('label',{staticClass:"user_profile_upload"},[_c('span',[_vm._v("Choose a File")]),_c('span',{staticStyle:{"float":"right","color":"#767272","font-size":"14px"}},[_vm._v("PDF Format Recommended")]),_c('input',{ref:"sla",class:errors[0] ? 'warning-border' : '',attrs:{"type":"file","accept":".pdf"}})])])]}}],null,true)})],1)])])]}}])}),_c('div',{staticClass:"pop_buttons_container"},[_c('a',{staticClass:"tr_cancel",on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('a',{staticClass:"tr_save",on:{"click":_vm.submit}},[_vm._v("Update")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }